<template>
	<tr>
		<td><input v-model="data.checked" :value="data.checked" @change="checkboxOrder" class="checkbox-height"
				   type="checkbox"/></td>
		<td class="text-xs">{{ data.article_number }}</td>
		<td class="text-xs"><span>{{ data.description }}</span></td>
		<td class="text-xs">
			<span @click="toggleQuantity" v-if="!updateQuantity">{{ data.quantity_in_stock }}</span>
			<input :value="data.quantity_in_stock" @keypress.enter="updateQuantityData($event, data)"
				   @change="updateQuantityData($event, data)" class="focus:outline-none w-full h-full p-2 z-50 relative"
				   v-else/>
		</td>
		<td class="text-xs">
			<span @click="togglePrice" v-if="!updatePrice">{{ data.sales_price }}</span>
			<input :value="data.sales_price" @keypress.enter="updatePriceData($event, data)"
				   @change="updatePriceData($event, data)" class="focus:outline-none w-full h-full p-2 z-50 relative"
				   v-else/>
		</td>
		<td class="text-xs">
			<span @click="togglePurchasePrice" v-if="!updatePurchasePrice">{{ data.purchase_price }}</span>
			<input :value="data.purchase_price" @keypress.enter="updatePurchasePriceData($event, data)"
				   @change="updatePurchasePriceData($event, data)"
				   class="focus:outline-none w-full h-full p-2 z-50 relative" v-else/>
		</td>
		<td class="text-xs">
			<span>{{ data.vat_rate }}</span>
		</td>
		<td class="text-xs">
			<span @click="toggleMargin" v-if="!updateMargin"
				  :class="parseInt(data.margin) < 5 ? 'text-red-500 font-semibold' : 'text-blue-900 font-semibold'">{{
					data.margin
				}}</span>
			<input :value="data.margin" @keypress.enter="updateMarginData($event, data)"
				   @change="updateMarginData($event, data)" class="focus:outline-none w-full h-full p-2 z-50 relative"
				   v-else/>
		</td>
	</tr>
</template>

<script>
import {mapGetters} from 'vuex'
import {CalculateGrossMargin} from "./../../../services/storage-window"

export default {
	name: 'ArticleSpreadSheetTable',
	props: {
		data: {
			type: Object,
			require: true,
		},
		id: {
			type: Number,
			require: true
		}
	},
	data() {
		return {
			checked: false,
			updateQuantity: false,
			updatePrice: false,
			updatePurchasePrice: false,
			updateVatRate: false,
			updateMargin: false,
			quantityValue: 0,
			priceValue: 0,
			purchasePriceValue: 0,
			vatRateValue: 0,
			marginValue: 0
		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION'
		})
	},
	mounted() {
		window.Bus.$on('remove-editable-input', _ => {
			this.updateQuantity = false
			this.updatePrice = false
			this.updatePurchasePrice = false
			this.updateVatRate = false
			this.updateMargin = false
		})
		// let self = this
		// setTimeout(function () {
		// 	self.$store.commit('portal/UPDATE_SINGLE_MARGIN', {
		// 		sales_price: self.data.sales_price, id: self.id,
		// 		purchase_price: self.data.purchase_price, vat_rate: self.data.vat_rate
		// 	})
		// }, 200)

	},
	methods: {
		checkboxOrder(e) {
			this.$store.commit('portal/TOGGLE_CHECKBOX', {id: this.data.id, value: this.data.checked})
		},
		calMargin: function () {

		},
		updateQuantityData(e, oldVal) {
			if (parseInt(e.target.value)) {
				this.$emit('updateQuantity', {value: e.target.value, row: this.data.id})
			} else {
				this.$emit('updateQuantity', {value: oldVal.quantity_in_stock, row: this.data.id})
				return false
			}
			this.updateQuantity = false
			window.Bus.$emit('update-editable-table')
		},
		updatePriceData(e, oldVal) {
			if (parseInt(e.target.value)) {
				this.$emit('updateSalesPrice', {salesPrice: e.target.value, id: this.id})
			} else {
				this.$emit('updateSalesPrice', {salesPrice: oldVal.sales_price, id: this.id})
				return false
			}
			this.updatePrice = false
			window.Bus.$emit('update-editable-table')
		},
		updatePurchasePriceData(e, oldVal) {
			if (parseInt(e.target.value)) {
				this.$emit('updatePurchasePrice', {costPrice: e.target.value, id: this.id})
			} else {
				this.$emit('updatePurchasePrice', {costPrice: oldVal.purchase_price, id: this.id})
				return false
			}
			this.updatePurchasePrice = false
			window.Bus.$emit('update-editable-table')
		},
		updateMarginData(e, oldVal) {
			if (parseInt(e.target.value)) {
				this.$emit('updateMarginValue', {margin: e.target.value, id: this.id})
			} else {
				this.$emit('updateMarginValue', {margin: oldVal.margin, id: this.id})
				return false
			}
			this.updateMargin = false
			window.Bus.$emit('update-editable-table')
		},
		toggleQuantity() {
			this.updateQuantity = !this.updateQuantity
			if (this.updateQuantity) {
				window.Bus.$emit('update-editable-table')
			}
		},
		togglePrice() {
			this.updatePrice = !this.updatePrice
			if (this.updatePrice) {
				window.Bus.$emit('update-editable-table')
			}
		},
		togglePurchasePrice() {
			this.updatePurchasePrice = !this.updatePurchasePrice
			if (this.updatePurchasePrice) {
				window.Bus.$emit('update-editable-table')
			}
		},
		toggleVatRate() {
			this.updateVatRate = !this.updateVatRate
			if (this.updateVatRate) {
				window.Bus.$emit('update-editable-table')
			}
		},
		toggleMargin() {
			this.updateMargin = !this.updateMargin
			if (this.updateMargin) {
				window.Bus.$emit('update-editable-table')
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.table tbody tr > td {
	// padding: 0.1rem 0.55rem !important;
	font-size: 12px !important;
	text-align: left;
	color: #6F6F6F;
}

.bg-green-custom {
	background: #0CD775;
}
</style>
