<template>
	<tr>
		<td><span>{{ unit.title }}</span></td>
		<td><span>{{ unit.unit }}</span></td>
		<td>
			<div class="flex justify-center space-x-2">
                                                <span v-on:click="updateUnit"
													  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                                                    <img src="./../../../../assets/images/icons/edit.png" alt="">
                                                </span>
				<span v-on:click="deleteUnit"
					  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                                                    <img src="./../../../../assets/images/icons/delete.png" alt="">
                                                </span>
			</div>
		</td>
	</tr>
</template>

<script>
import {UNIT_TYPES} from "../../../../services/constant"

export default {
	name: "unitTypeTbl",
	props: {
		unit: {
			type: Object,
			required: true
		}
	},
	methods: {
		getUnit: function () {
			let unit = UNIT_TYPES.filter(item => item.key === this.unit.unit)
			if (unit.length > 0) {
				return unit[0].title
			}
			return ""
		},
		updateUnit: function () {
			window.Bus.$emit('open-update-new-unit', this.unit)
		},
		deleteUnit: function () {
			this.$confirm(`${this.$t('removeUnitType')} ${this.unit.title}?`, `${this.$t('warning')}`, {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('cancel'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true
						instance.confirmButtonText = 'Loading...'
						this.$store.dispatch('portal/deleteUnit', {
							'id': this.unit.id
						}).then(_ => {
							instance.confirmButtonLoading = false
							done()
							window.Bus.$emit('reload-unit', {
								id: this.unit.id
							})
						}).catch(error => {
							done()
							instance.confirmButtonLoading = false
							window.Bus.$emit('output-error', error)
						})
					} else {
						done()
						instance.confirmButtonLoading = false
					}
				}
			}).then(() => {
				window.Bus.$emit('reload-unit', {
					id: this.unit.id
				})
			})
		}
	}
}
</script>

<style scoped>

</style>
