<template>
	<div class="row">
		<div class="col-md-12 mt-2 flex justify-center md:justify-end mb-4 bg-white border rounded-md p-2">

			<div class="flex justify-around w-full lg:justify-end  space-x-4">
				<span class="md:mb-0 w-full lg:w-2/6 ">
                            <input type="text" v-model="searchName" @input="searchInputTrigger"
								   class="flex justify-between w-full align-items-center space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500"
								   v-bind:placeholder="$t('search')">
                        </span>
				<span style="height: 32px;width: 32px" v-on:click="addNew"
					  class="cursor-pointer mt-1">
											<img alt="add row" id="image0_1507_294" width="32" height="32"
												 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAAAXNSR0IArs4c6QAAA3dJREFUeF7t3D1Pk1EUB/D/tS2Y+MKq4MBQNEajm8TvwCdQKcRBJ3CEwTjo4MukwUUHQyuyuOp3kA2j0agPmy+zghqblmseE0w1Jbfn9Ob4ePkzNuel9/echec014F/JgLOpAubgNBGQ0BoQhsJGLXhRBPaSMCoDSea0EYCRm040YQ2EjBqw4kuOvT0KHZvVnDReZwBcAzAHqPvbN3mqwNeeofl/R73FjL80HwB1USfreJQ2eEpPE5omv6vOR543gYmHmV4Lz2DGDqfZF/Byk5D3oLNsYeAcelki6FrY7jkPG5Ln2hK8c5jdnENC5IziaGnqlgBcErSJMHYZ/UMpyXn0kCvA9graZJg7EY9wz7JuTTQXtIg1dh6JnuXT2jlJBBaCSdNI7RUTBlPaCWcNI3QUjFlfMrQTedwuVXGUm5TamESHtcADCit+kpLF9phrv4Otzp1alXMOeBGX2LK5GSh2xUML73Gp06X6VEc8OU/P1O6idOShd7uYFNV/JN/oAgtnk1dAqF1buIsQovJdAmE1rmJswgtJtMlEFrnJs4itJhMl0BonZs4i9BiMl0CoXVu4ixCi8l0CYTWuYmzCC0m0yUQWucmzioi9O/NyN/vk8Wni5xw7igOajc1xYPushmJ7NV3Oc2mpnDQ3TYjfctELqDZ1BQOulTCyIM3+BjZJmq52mGMuE3Zb54LB+2B+UaGm1FlIhebrmLeA9clZQsHDaDpgSvlEh4WbbLPH8Fwq41JB1yV/myhiNCSQdk2lsvZKIzhIoQOG0WJIHQUxnARQoeNokQQOgpjuAihw0ZRIggdhTFchNBhoygRhI7CGC5C6LBRlAhCR2EMFyF02ChKBKGjMIaLEDpsFCWC0FEYw0W6bWo0m5Fwp94ikn0f3W1To9mM9MYYjkoWunNTkzNoNyNhwt4iUobuTcAoitCENhIwasOJJrSRgFEbTnRC0LyODfhSzzAkeaaaW8J4wSBgcMHgGGbhcUfyNFOL9R4zjTXclZxLPNEzVQx+BlYccFLSKKHY1W8DGH/8Ck3JmcTQefH8WuMS8GQHYq/6XZhovMUHCXIeq4LOE/PJXve44N2vi7qPJ3xf6QaAF95j+fsg7ksneeuBqKGlT3SnxxPaaAIITWgjAaM2nGhCGwkYteFEE9pIwKgNJ5rQRgJGbTjRhDYSMGrzE6KzlmrUJExFAAAAAElFTkSuQmCC"/>
										</span>
			</div>
		</div>
		<div data-cy='table1' class="w-full mt-3"> <!--pl-5 pr-5-->
			<div class="table-responsive rounded-lg bg-white border">
				<table v-loading='loading' style="width: 100%;" id="exampledestroy"
					   class="table table-hover table-bordered">
					<thead>
					<tr>
						<th width='50px'><span>{{ $t('title') }}</span></th>
						<th width='50px'><span>{{ $t('unit') }}</span></th>
						<th class="text-xs w-20 v-article-2">{{ $t('actions') }}</th>
					</tr>
					</thead>
					<tbody v-for="(unit, i) in GET_UNITS.results" :key="i">
					<unit-type-tbl :unit="unit"/>
					</tbody>
				</table>
			</div>
			<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="getUnitTypes">
                                    <option value="50" selected>50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                </select>
                            </span>
                        </span>
				<div class="pagination-block" v-if="loading"> <!--GET_ARTICLES.total_pages--->
					<paginate
						:page-count="total_pages"
						:click-handler="changePageNumber"
						prev-text="<"
						next-text=">"
						:container-class="'pagination space-x-2'">
					</paginate>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import unitTypeTbl from "../../../views/portal/components/unitTypeComponent/unitTypeTbl";
import debounce from 'lodash.debounce'
import {mapGetters} from "vuex";

export default {
	name: "UnitTypeComponent",
	components: {
		paginate: Paginate,
		unitTypeTbl
	},
	data: function () {
		return {
			loading: false,
			showFilter: false,
			limit: 50,
			searchName: '',
			total_pages: 1
		}
	},
	computed: {
		...mapGetters({
			GET_UNITS: 'portal/GET_UNIT_TYPE'
		})
	},
	methods: {
		changePageNumber: function (num) {
			this.loading = true
			this.$store.dispatch('portal/fetchUnits', {data: `?limit=${this.limit}&page=${num}`}).then(response => {
				this.loading = false

				this.getTotalPage(response.data.count)
			}).catch(err => {
				this.loading = false
				window.Bus.$emit('output-error', err)
			})
		},
		searchInputTrigger: debounce(function () {
			this.loading = true
			let URL = `?limit=${this.limit}`
			if (this.searchName) {
				URL += `&search=${this.searchName}`
			}
			this.$store.dispatch('portal/fetchUnits', {data: URL}).then(response => {
				this.loading = false
				this.getTotalPage(response.data.count)
			}).catch(err => {
				this.loading = false
				window.Bus.$emit('output-error', err)
			})
		}),
		addNew: function () {
			window.Bus.$emit('open-add-new-unit')
		},
		getUnitTypes: function () {
			this.loading = true
			this.$store.dispatch('portal/fetchUnits', {data: `?limit=${this.limit}`}).then(response => {
				this.loading = false
				this.getTotalPage(response.data.count)
			}).catch(err => {
				this.loading = false
				window.Bus.$emit('output-error', err)
			})
		},
		getTotalPage: function (pageCount) {
			if (parseInt(pageCount) > this.limit) {
				this.total_pages = Math.ceil(pageCount / this.limit)
			} else {
				this.total_pages = 1
			}
		}
	},
	mounted() {
		this.getUnitTypes()
		window.Bus.$on('reload-unit', e => {
			this.getUnitTypes()
		})

	}
}
</script>

<style scoped>

</style>
