<template>
	<tr>
		<td><input v-model="data.checked" :value="data.checked" @change="checkboxOrder" class="checkbox-height"
				   type="checkbox"/></td>
		<td class="text-xs">{{ data.article_number }}</td>
		<td class="text-xs">
			<span>{{ showProductCategory(data) }}</span>
		</td>
		<td class="text-xs"><span>{{ cleanData(data.description) }}</span></td>
		<td class="text-xs">
            <span v-if="data.product_video_url"
				  :class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.link ? '' : 'text-blue-600'">
                <a :style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.link ? 'color:' + GET_CUSTOMIZATION.link : ''"
				   @click="redirectToUrl(data.product_video_url)" href="javascript:void(0);">{{
						cleanData(data.product_video_url)
					}}</a></span>
			<span v-else>---</span>
		</td>
		<td class="text-xs">
			<div class="absolute bg-red-30 inset-0 z-100" style="z-index:2001"
				 @click.prevent="relevantOpen = false"
				 v-if="relevantOpen"></div>
			<span class="position-relative">
				<span class="w-full badge h-8 text-white cursor-pointer " @click="fireOpenRelevant"
					  :class="{'bg-green-custom': relevantProduct === 'true','bg-red-500': relevantProduct === 'false','bg-blue-500': relevantProduct === 'select'}">
				<span class="relevant">{{ relevantText }}</span>

			</span>

				<div class="absolute w-60 bg-white rounded-md border shadow-lg md:-ml-20" style="z-index:9999"
					 v-if="relevantOpen=== true">
							 <div>
                    <div class="bg-white border-b" v-on:click="onChangeRelevant('true')">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('yes') }} </span>
                        </label>
                    </div></div>
                    <div class="bg-white border-b" v-on:click="onChangeRelevant('false')">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('no') }} </span>
                        </label>
                    </div>
                    <div class="bg-white border-b" v-on:click="onChangeRelevant('select')">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('CustomerSpecific') }} </span>
                        </label>
                    </div>
				</div>

			</span>
		</td>
		<td>
			<div v-if="data.image !== null">
				<div class="flex space-x-2" @click="openModalImage(data)" v-if="data.images.length > 0">
                    <span v-for="img in data.images" :key="img.id">
                        <v-lazy-image class="w-10 hover:w-20 cursor-pointer" :src="img.image" alt=""/>
						<!--					<v-lazy-image :src="img.image" class="w-10 hover:w-20 cursor-pointer"/>-->
                    </span>
				</div>
			</div>
		</td>
		<td>
			<div class="flex justify-center space-x-3">
                <span @click="openArticleModal(data,index)" class="cursor-pointer" title="Upload article pictures">
                    <i class="iconly-brokenUpload"></i>
                </span>
			</div>
		</td>
		<td>
            <span class="flex justify-center space-x-2">
                <span @click="$emit('openArticleUpdate', data)"
					  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                    <img src="./../../../assets/images/icons/edit.png" alt="">
                </span>
                <span @click="deleteArticle"
					  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                    <img src="./../../../assets/images/icons/delete.png" alt="">
                </span>
            </span>
		</td>
	</tr>
</template>

<script>
import {mapGetters} from 'vuex'
import VLazyImage from 'v-lazy-image/v2'

export default {
	name: 'ArticleTableComponent',
	components: {
		VLazyImage
	},
	props: {
		data: {
			type: Object,
			require: true,
		},
		index: {
			type: Number,
			require: true
		}
	},
	data() {
		return {
			checked: false,
			relevantProduct: '',
			isRelevent: '',
			categories: '',
			relevantText: this.$t('yes'),
			relevantOpen: false
		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION'
		})
	},
	watch: {
		data: function (newVal, oldVal) {

			// this.relevantProduct = newVal.is_relevant
		}
	},
	mounted() {
		if (this.data.is_hidden === true && this.data.is_relevant === true) {
			this.relevantProduct = 'select'
			this.relevantText = this.$t('CustomerSpecific')
		} else {
			if (this.data.is_relevant === true) {
				this.relevantProduct = 'true'
				this.relevantText = this.$t('yes')
			} else {
				this.relevantProduct = 'false'
				this.relevantText = this.$t('no')
			}

		}
		this.getArticleCategory()
	},
	methods: {
		fireOpenRelevant: function () {
			this.relevantOpen = !this.relevantOpen
		},
		openArticleModal(data, index) {
			data.index = index
			this.$emit('openArticleModal', data)
		},
		openModalImage(data) {
			data.index = this.index
			this.$emit('openModalImage', data)
		},
		onChangeRelevant(status) {
			this.relevantProduct = status
			let listDict = {'true': this.$t('yes'), 'false': this.$t('no'), 'select': this.$t('CustomerSpecific')}
			this.relevantText = listDict[status]
			this.relevantOpen = false
			this.$emit('checkArticleRelevant', {data: status, article: this.data.id, section: 'child'})


		},
		checkboxOrder(e) {
			this.$store.commit('portal/TOGGLE_CHECKBOX', {id: this.data.id, value: this.data.checked})
		},
		showProductCategory(data) {
			const categories = []
			if (data.hasOwnProperty('categories')) {
				if (data.categories.length > 0) {
					data.categories.map(v => categories.push(v.name))
					if (categories.length > 0) {
						return categories.join(', ')
					} else {
						return '----'
					}
				} else {
					return '---'
				}
			}
			// if (data.parent_category_name.length > 0 || data.sub_category_name.length > 0) {
			// 	data.parent_category_name.map(v => categories.push(v))
			// 	data.sub_category_name.map(v => categories.push(v))
			// }
			// if (categories.length > 0 && categories[0] !== null) {
			// 	return categories.join(', ')
			// } else {
			// 	return '----'
			// }
		},
		redirectToUrl(url) {
			window.open(url, '_blank')
		},
		getArticleCategory: function () {
			this.categories = ''
			this.data.categories.map(item => {
				this.categories += item.name + ', '
			})
		},
		cleanData: function (data) {
			if (['nan'].includes(data)) {
				return ''
			} else {
				return data
			}
		},
		deleteArticle: function () {
			let self = this
			this.$confirm(`${this.$t('deleteArticle')}?`, `${this.$t('warning')}`, {
				confirmButtonText: this.$t('delete'),
				cancelButtonText: this.$t('cancel'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true
						instance.confirmButtonText = 'Loading...'
						self.$store.dispatch('portal/deleteArticle', {id: this.data.id})
							.then(res => {
								this.$services.helpers.notification(`${self.$t('deleteSuccess')}`, 'success', this)
								done()
								instance.confirmButtonLoading = false
								window.Bus.$emit('remove-article', this.data.id)
							}).catch((err) => {
							done()
							instance.confirmButtonLoading = false
							window.Bus.$emit('output-error', err)
						})
					} else {
						done()
					}
				}
			}).then(() => {

			})
				.catch(() => {

				})
		}
	}
}
</script>

<style lang="scss" scoped>
.table tbody tr > td {
	// padding: 0.1rem 0.55rem !important;
	font-size: 12px !important;
	text-align: left;
	color: #6F6F6F;
}

.bg-green-custom {
	background: #0CD775;
}

.bg-relevant {
	background: #e5e7eb;
	padding: 10px 5px;
	text-align: center;
	border-radius: 7px;
	font-weight: 600;
	width: 100%;
}

.relevant {
	position: relative;
	top: 7px;
	font-weight: 700;
	font-size: 10px;
}
</style>
